import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import env from '../../env-config';
import Swal from 'sweetalert2';
import axios from 'axios';
import InputComponent from './InputsDialog';
import 'bootstrap/dist/css/bootstrap.min.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClose }) {
  const getValueRefs = useRef({});



  const handleAdd = async () => {

    const idLogin = sessionStorage.getItem('loginUser');
    const actualUser = sessionStorage.getItem('loginNick'); 

    const data = {
      Name: getValueRefs.current["Nombre"].value,
      User: getValueRefs.current["Usuario"].value,
      Pass: getValueRefs.current["Pass"].value,
      Mail: getValueRefs.current["Correo"].value, 
      Rol: document.getElementById("ROL").value,
      IDUSUARIO: idLogin
    };


    try {

      
      const response = await axios.post(env.API_URL+'AddUser', data);
      if (response.status === 200) {

        const dataBit = {
          ID: idLogin,
          Descripcion: ` ${actualUser} ha creado el usuario ${data.Name}`
        }

        
        await axios.post(env.API_URL+'AddActionRecord', dataBit);

        Swal.fire('Success', 'Se ha creado el usuario', 'success').then(() => {
          window.location.reload();
        }); 
      } else {
        Swal.fire('Error', 'Hubo un problema al crear el usuario', 'error');
      }
      handleClose();
    } catch (error) {
      console.log('error:', error.message); 
      Swal.fire('Error', 'Hubo un problema al establecer la conexión', 'error');
      handleClose();
    }
    
  };


  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >

        <DialogTitle>{"Crear Usuario"}</DialogTitle>
        <DialogContent>
          <div className='Row'>
            <InputComponent
              getValue={getValueRefs}
              id="Nombre"
              label="Nombre:"
              type="text"
              placeholder=""
            />
            <InputComponent
              getValue={getValueRefs}
              id="Correo"
              label="Correo:"
              type="text"
              placeholder=""
            />
            <InputComponent
              getValue={getValueRefs}
              id="Usuario"
              label="Usuario:"
              type="text"
              placeholder=""
            />
          </div>

          <div className='Row'>
            <InputComponent
              getValue={getValueRefs}
              id="Pass"
              label="Contraseña:"
              type="text"
              placeholder =""
            />

            <div className="col-md-4 col-sm-4">
              <label className="form-label">Rol</label>
              <select className="form-control" id="ROL">
                <option value="SU">Administrador</option>
                <option value="Normal">Usuario</option>
              </select>
            </div>
          </div>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleAdd}>Agregar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
