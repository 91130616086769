import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import env from '../../../env-config';

import InputEditComponent from './InputsEditDialog';
import 'bootstrap/dist/css/bootstrap.min.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditUserModal({ open, handleClose }) {
  const [ID, setID] = useState('');
  const [data, setData] = useState({});
  const [rol, setRol] = useState(''); 
  const rolRef = useRef(null);
  const [reset, setReset] = useState(false);
  const getValueRefs = useRef({});



  useEffect(() => {
    if (open) {
      const ID = sessionStorage.getItem('UserID');
      setID(ID);

      const fetchData = async () =>{
        const data2 = {
          ID: ID
        }
        try {
          const response = await axios.post(env.API_URL + 'GetUser', data2);
          const formattedData = response.data[0];
          setData(formattedData);
          setRol(formattedData.Rol);
        } catch (error) {
          console.error("Error en traer la data del usuario", error.message);
        }
      }
      fetchData();
    } else {
      // Reset the values when the modal is closed
      setReset(true);
      setTimeout(() => setReset(false), 0);
    }
  }, [open]);


  const detectChanges = () => {
    const changes = [];
    const actualUser = sessionStorage.getItem('loginNick'); // Usando el usuario actual
  
    // Comparar valores de los campos de texto
    Object.keys(getValueRefs.current).forEach((key) => {
      const currentValue = getValueRefs.current[key].value;
      if (currentValue !== data[key]) {
        changes.push(`Se ha cambiado ${key} de "${data[key]}" por "${currentValue}"`);
      }
    });


    if (rol !== data.Rol) {
      const rolOriginal = data.Rol === 'SU' ? 'Administrador' : 'Normal';
      const rolCambio = rol === 'SU' ? 'Administrador' : 'Normal';
      changes.push(`Se ha cambiado el Rol de "${rolOriginal}" por "${rolCambio}"`);
    }
  
  
    return `El usuario ${actualUser} realizó los siguientes cambios en el usuario ${data.Nombre}: ${changes.join(' * ')}`;
  };
  

  const handleEdit = async () => {
    const idLogin = sessionStorage.getItem('loginUser');

    const changes = detectChanges();


    const rawData = {
      Name: getValueRefs.current["Nombre"].value,
      User: getValueRefs.current["Usuario"].value,
      Mail: getValueRefs.current["Correo"].value,
      Rol: rol,
      ID: ID,
      IDUSUARIO: idLogin
    };

    

    
    
    try {
      const response = await axios.put(env.API_URL + 'UpdateUser', rawData);

      if (response.status === 200) {
        const dataBit = {
          ID: idLogin,
          Descripcion: changes
        }

        await axios.post(env.API_URL+'AddActionRecord', dataBit);
        
        Swal.fire('Success', 'Usuario se ha editado exitosamente', 'success').then(() => {
          window.location.reload();
        });
      } else {

        Swal.fire('Error', 'Hubo un problema al editar el Usuario', 'error');
      }
      handleClose();
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al establecer la conexión', 'error');
      handleClose();
    }
      
      
      
  };

  const handleRoleChange = (e) => {
    setRol(e.target.value); // Actualizar solo el estado `rol`
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{`Editar a ${data.Nombre}`}</DialogTitle>
        <DialogContent>
          <div className='row'>
            <InputEditComponent
              getValue={getValueRefs}
              id="Nombre"
              label="Nombre:"
              type="text"
              placeholder=""
              Avalue={data.Nombre}
              reset={reset}
            />
            <div className="col-md-4 col-sm-4">
              <label className="form-label">Rol</label>
              <select
                className="form-control"
                id="Rol"
                value={rol} // El valor aquí está controlado por el estado `rol`
                onChange={handleRoleChange} // Solo actualiza `rol`
                ref={rolRef}
              >
                <option value="SU">Administrador</option>
                <option value="Normal">Normal</option>
              </select>
            </div>
            <InputEditComponent
              getValue={getValueRefs}
              id="Usuario"
              label="Usuario:"
              type="text"
              placeholder=""
              Avalue={data.Usuario}
              reset={reset}
            />
          </div>


          <div className='row'>
              <InputEditComponent
                getValue={getValueRefs}
                id="Correo"
                label="Correo:"
                type="text"
                placeholder=""
                Avalue={data.Correo}
                reset={reset}
              />
          </div>



        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleEdit}>Editar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
