
import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import {DeleteFilled,EditFilled} from '@ant-design/icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import env from '../../env-config';
import EditUser from '../../subcomponents/modals/Edit/EditUser'


import {Button,Spin} from 'antd';



const Title = "Usuarios";

const columns = ["ID","Nombre", "Rol", "Usuario","Correo","Acciones Realizadas"];




const Usuarios = () => {

  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Estado de carga

  //Logica para Editar
  const handleEditModalOpen = async () => {

    if (selectedRows.length === 0) {
      return;
    }

    const selectedUser = data[selectedRows[0].dataIndex];
    const ID = selectedUser[0];
    sessionStorage.setItem('UserID',ID);

    setEditModalOpen(true);
    
  };
  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  //Logica para eliminar
  const handleDelete = async () => {

    if (selectedRows.length === 0) {
      return;
    }

    const selectedUser = data[selectedRows[0].dataIndex];
    const id = selectedUser[0];
    const nombre = selectedUser[1];
    const idLogin = sessionStorage.getItem('loginUser');
    const actualUser = sessionStorage.getItem('loginNick');

 
    try {
      const response = await axios.post(env.API_URL+'DeleteUser', 
        {
          ID: id
        }
      );
      if (response.status === 200) {
        const dataBit = {
          ID: idLogin,
          Descripcion: "El usuario " + actualUser + " eliminó al usuario " + nombre
        }
        await axios.post(env.API_URL+'AddActionRecord', dataBit);
        Swal.fire('Success', 'Cliente se ha eliminado exitosamente', 'success').then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire('Error', 'Hubo un problema al eliminar el cliente', 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al eliminar el cliente', 'error');
    }
      
  };


  // Cargar Datos
  useEffect(() => {
    const user = sessionStorage.getItem('loginUser');
    const rol = sessionStorage.getItem('loginRol');
    if (!user || rol !== 'SU') {
      // Redirigir al login si no hay compañía
      window.location.href = '/login';
      return;
    }
    
    const fetchData = async () => {


      try {
        const response = await axios.get(env.API_URL+'GetUsers');
        const formattedData = response.data.map(user => [
          user.ID,
          user.Nombre,
          user.Rol === "SU" ? "Administrador" : "Normal",
          user.Usuario,
          user.Correo,
          user.CantidadAcciones
        ]);
        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data", error.message);
      }finally {
        setIsLoading(false); // Cambia el estado de carga
      }
    };

    fetchData();
  }, []);


  const customToolbarSelect = () => {

  
      return (
        <>
          <div>
            <Button type='text' className='toggle' icon={<EditFilled />} title="Editar" onClick={handleEditModalOpen} />
            <Button type='text' className='toggle' icon={<DeleteFilled />} onClick={handleDelete} title="Borrar" />
            
            
          </div>
        </>
      );
    };
  
    return (
            
        <>
          <EditUser open={editModalOpen} handleClose={handleEditModalClose} />
          {isLoading ? (
        <Spin tip="Cargando datos..." style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }} />
      ) : (
          <Table 
          title={Title} 
          data={data} 
          columns={columns} 
          customToolbarSelectFunction={customToolbarSelect} 
          setSelectedRows={setSelectedRows}
          />)}
        </>

        
    );
    }
export default Usuarios;

