import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import env from '../../env-config';
import Swal from 'sweetalert2';
import axios from 'axios';
import InputComponent from './InputsDialog';
import 'bootstrap/dist/css/bootstrap.min.css';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { esES } from '@mui/x-date-pickers/locales';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateMueble({ open, handleClose }) {
  const getValueRefs = useRef({});
  const [buyTime, setBuyTime] = useState(dayjs()); 

  const handleAdd = async () => {

    let URL = env.API_URL;

    let ID = ''; 
    const TipoBien = sessionStorage.getItem('TipoBien');
    const actualUser = sessionStorage.getItem('loginNick'); 
    let refCreate = '' ;


    if (TipoBien === 'Sociedad') {
      URL = URL + 'AddMueblesSociedades';
      ID = sessionStorage.getItem('SocietyID');
      refCreate = 'para la sociedad ' + sessionStorage.getItem('SocietyName');
    }
    else{
      URL = URL + 'AddMueblesCliente';
      ID = sessionStorage.getItem('CustomerID');
      refCreate = 'para el cliente ' + sessionStorage.getItem('CustomerName');
    }

    const idLogin = sessionStorage.getItem('loginUser');

    const data = {
      Name: getValueRefs.current["Nombre"].value,
      Registration: getValueRefs.current["Matricula"].value,
      BuyDate: buyTime ? dayjs(buyTime).format('YYYY-MM-DD') : null,
      FiscalValue: getValueRefs.current["ValorFiscal"].value,
      ID: ID,
      IDUSUARIO: idLogin
    };

    console.log(data);
    
    
    try {
      const response = await axios.post(URL, data);
      if (response.status === 200 && response.data[0].Mensaje === 'OK') {

        const dataBit = {
          ID: idLogin,
          Descripcion: ` ${actualUser} ha creado el mueble con nombre ${data.Name}, ${refCreate} `
        }

        
        await axios.post(env.API_URL+'AddActionRecord', dataBit);
        Swal.fire('Success', 'El mueble se ha editado exitosamente', 'success').then(() => {
          window.location.reload();
        });
      } else {

        Swal.fire('Error', 'Hubo un problema al crear el mueble ( Ya existe esa matricula )'  , 'error');
      }
      handleClose();
    } catch (error) {
      console.log('error:', error.message); 
      Swal.fire('Error', 'Hubo un problema al establecer la conexión', 'error');
      handleClose();
    }
    
  };


  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >

        <DialogTitle>{"Crear Mueble"}</DialogTitle>
        <DialogContent>
          <div className='Row'>
            <InputComponent
              getValue={getValueRefs}
              id="Nombre"
              label="Nombre:"
              type="text"
              placeholder=""
            />
            <InputComponent
              getValue={getValueRefs}
              id="Matricula"
              label="Matricula:"
              type="text"
              placeholder=""
            />
            <InputComponent
              getValue={getValueRefs}
              id="ValorFiscal"
              label="Valor Fiscal(₡):"
              type="number"
              placeholder =""
            />
            
          </div>
          <div className='Row'>
            <div className="col-md-4 col-sm-4">
              <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="FechaCompra"
                    value={buyTime}
                    onChange={(newValue) => setBuyTime(newValue)}
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleAdd}>Agregar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
