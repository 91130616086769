import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import env from '../../../env-config';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { esES } from '@mui/x-date-pickers/locales';

import InputEditComponent from './InputsEditDialog';
import 'bootstrap/dist/css/bootstrap.min.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditSocietyModal({ open, handleClose }) {
  const [ID, setID] = useState('');
  const [data, setData] = useState({});
  const EstadoRef = useRef(null);
  const [reset, setReset] = useState(false);
  const getValueRefs = useRef({});
  const [inTime, setInTime] = useState(null); // Inicializando con la fecha actual
  const [outTime, setOutTime] = useState(null); // Inicializando con la fecha actual

  const [tempEstado, setTempEstado] = useState(null); 


  useEffect(() => {
    if (open) {
      const ID = sessionStorage.getItem('SocietyID');
      setID(ID);

      const fetchData = async () =>{
        const data2 = {
          ID: ID
        }
        try {
          const response = await axios.post(env.API_URL + 'GetSociety', data2);
          const formattedData = response.data[0];


          const adjustDate = (dateStr) => {
            if (!dateStr) return null;
            return dayjs(dateStr); // Mantener la fecha original sin conversión
          };
          
          const originalIngresoStr = formattedData.Ingreso;
          const originalRetiroStr = formattedData.Retiro;

          // Si Ingreso es null, no hacer nada y establecer inTime a null
          if (originalIngresoStr) {
            const ingresoDate = adjustDate(originalIngresoStr);

            const ingresoDay = parseInt(originalIngresoStr.split('T')[0].split('-')[2], 10); // Extraer el día sin conversión
            const ingresoBuyDay = ingresoDate.date();

            const ingresoDayDifference = ingresoBuyDay - ingresoDay;

            // Ajustar la fecha de ingreso si los días son diferentes
            if (ingresoDayDifference !== 0) {
              const adjustedIngresoDate = ingresoDate.add(-ingresoDayDifference, 'day');
              setInTime(adjustedIngresoDate);
            } else {
              setInTime(ingresoDate);
            }
          } else {
            setInTime(null); // Asegúrate de que inTime sea null si no hay fecha
          }

          // Si Retiro es null, no hacer nada y establecer outTime a null
          if (originalRetiroStr) {
            const retiroDate = adjustDate(originalRetiroStr);

            const retiroDay = parseInt(originalRetiroStr.split('T')[0].split('-')[2], 10); // Extraer el día sin conversión
            const retiroBuyDay = retiroDate.date();

            const retiroDayDifference = retiroBuyDay - retiroDay;

            // Ajustar la fecha de retiro si los días son diferentes
            if (retiroDayDifference !== 0) {
              const adjustedRetiroDate = retiroDate.add(-retiroDayDifference, 'day');
              setOutTime(adjustedRetiroDate);
            } else {
              setOutTime(retiroDate);
            }
          } else {
            setOutTime(null); // Asegúrate de que outTime sea null si no hay fecha
          }
          setData(formattedData);
          setTempEstado(formattedData.Estado);
        } catch (error) {
          console.error("Error en traer la data de la sociedad", error.message);
        }
      }
      fetchData();
    } else {
      // Reset the values when the modal is closed
      setReset(true);
      setTimeout(() => setReset(false), 0);
    }
  }, [open]);


  const detectChanges = () => {
    const changes = [];
    const actualUser = sessionStorage.getItem('loginNick'); // Usando el usuario actual
  
    // Comparar valores de los campos de texto
    Object.keys(getValueRefs.current).forEach((key) => {
      const currentValue = getValueRefs.current[key].value;
      if (currentValue !== data[key]) {
        changes.push(`Se ha cambiado ${key} de "${data[key]}" por "${currentValue}"`);
      }
    });
  
    // Función para ajustar las fechas
    const adjustDate = (dateStr) => {
      if (!dateStr) return null;
      return dayjs(dateStr); // Convertir las fechas a dayjs para compararlas
    };
  
    const originalIngresoStr = data.Ingreso;
    const originalRetiroStr = data.Retiro;
    
  
    // Ajustar y comparar la fecha de Ingreso
    let FechaIngreso = adjustDate(originalIngresoStr);
    if (FechaIngreso) {
      const ingresoDay = parseInt(originalIngresoStr.split('T')[0].split('-')[2], 10);
      const ingresoBuyDay = FechaIngreso.date();
      const ingresoDayDifference = ingresoBuyDay - ingresoDay;
  
      // Ajustar la fecha de ingreso si los días son diferentes
      if (ingresoDayDifference !== 0) {
        FechaIngreso = FechaIngreso.add(-ingresoDayDifference, 'day');
      }
  
      // Comparación de la fecha de Ingreso
      if (inTime && !dayjs(inTime).isSame(FechaIngreso, 'day')) {
        const oldDate = FechaIngreso ? FechaIngreso.format('DD-MM-YYYY') : "N/A";
        const newDate = dayjs(inTime).format('DD-MM-YYYY');
        changes.push(`Se ha cambiado la Fecha de Ingreso de "${oldDate}" por "${newDate}"`);
      }
    }
  
    // Ajustar y comparar la fecha de Retiro
    let RetiroDate = adjustDate(originalRetiroStr);
    if (RetiroDate) {
      const retiroDay = parseInt(originalRetiroStr.split('T')[0].split('-')[2], 10);
      const retiroBuyDay = RetiroDate.date();
      const retiroDayDifference = retiroBuyDay - retiroDay;
  
      // Ajustar la fecha de retiro si los días son diferentes
      if (retiroDayDifference !== 0) {
        RetiroDate = RetiroDate.add(-retiroDayDifference, 'day');
      }
  
      // Comparación de la fecha de Retiro
      if (outTime && !dayjs(outTime).isSame(RetiroDate, 'day')) {
        const oldDate = RetiroDate ? RetiroDate.format('DD-MM-YYYY') : "N/A";
        const newDate = dayjs(outTime).format('DD-MM-YYYY');
        changes.push(`Se ha cambiado la Fecha de Retiro de "${oldDate}" por "${newDate}"`);
      }
    }
  
    // Comparación del estado
    const estadoNuevo = document.getElementById("Estado").value === "true"; // Asegúrate de obtener el valor correcto
    if (tempEstado!== estadoNuevo) {
      changes.push(`Se ha cambiado el Estado de "${tempEstado ? 'Activo' : 'Inactivo'}" por "${estadoNuevo ? 'Activo' : 'Inactivo'}"`);
    }
  
    // Retornar los cambios detectados
    return `El usuario ${actualUser} realizó los siguientes cambios en la sociedad ${data.Nombre}: ${changes.join(' * ')}`;
  };
  
  

  const handleEdit = async () => {
    const idLogin = sessionStorage.getItem('loginUser');
    const description = detectChanges();


    const rawData = {
      Name: getValueRefs.current["Nombre"].value,
      IDSociety: ID,
      ID: getValueRefs.current["Cedula"].value,
      Address: getValueRefs.current["Domicilio"].value,
      ResidentAgent: getValueRefs.current["AgenteResidente"].value,
      Books: getValueRefs.current["Libros"].value,
      Consecutive: getValueRefs.current["Consecutivo"].value,
      Income: inTime ? dayjs(inTime).format('YYYY-MM-DD') : null,
      Retirement: outTime ? dayjs(outTime).format('YYYY-MM-DD') : null,
      Representatives: getValueRefs.current["Representantes"].value,
      State: document.getElementById("Estado").value === "true",
      Lawyer: getValueRefs.current["Abogado"].value,
      Comment: getValueRefs.current["Comentario"].value,
      IDUSUARIO: idLogin
    };

 
  
    try {
      const response = await axios.put(env.API_URL + 'UpdateSociety', rawData);
      if (response.status === 200) {
        const dataBit = {
          ID: idLogin,
          Descripcion: description
        }

        await axios.post(env.API_URL+'AddActionRecord', dataBit);
        Swal.fire('Success', 'Usuario se ha editado exitosamente', 'success').then(() => {
          window.location.reload();
        });
      } else {

        Swal.fire('Error', 'Hubo un problema al editar el Usuario', 'error');
      }
      handleClose();
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al establecer la conexión', 'error');
      handleClose();
    }
 
      
  };


  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{`Editar a ${data.Nombre}`}</DialogTitle>
        <DialogContent>
          <div className='row'>
            <InputEditComponent
              getValue={getValueRefs}
              id="Nombre"
              label="Nombre:"
              type="text"
              placeholder=""
              Avalue={data.Nombre}
              reset={reset}
            />
            <InputEditComponent
              getValue={getValueRefs}
              id="Domicilio"
              label="Domicilio:"
              type="text"
              placeholder=""
              Avalue={data.Domicilio}
              reset={reset}
            />
            <InputEditComponent
              getValue={getValueRefs}
              id="Cedula"
              label="Cédula:"
              type="text"
              placeholder=""
              Avalue={data.Cedula}
              reset={reset}
            />
          </div>


          <div className='row'>

          <InputEditComponent
              getValue={getValueRefs}
              id="AgenteResidente"
              label="Agente Residente:"
              type="text"
              placeholder=""
              Avalue={data.AgenteResidente}
              reset={reset}
            />
              <InputEditComponent
                getValue={getValueRefs}
                id="Libros"
                label="Libros:"
                type="number"
                placeholder=""
                Avalue={data.Libros}
                reset={reset}
              />
              <InputEditComponent
                getValue={getValueRefs}
                id="Consecutivo"
                label="Consecutivo:"
                type="number"
                placeholder=""
                Avalue={data.Consecutivo}
                reset={reset}
              />
              
          </div>


          <div className='row'>

            <InputEditComponent
                getValue={getValueRefs}
                id="Representantes"
                label="Representantes:"
                type="text"
                placeholder=""
                Avalue={data.Representantes}
                reset={reset}
              />

              <InputEditComponent
                getValue={getValueRefs}
                id="Abogado"
                label="Abogado:"
                type="text"
                placeholder=""
                Avalue={data.Abogado}
                reset={reset}
              />

              <InputEditComponent
                getValue={getValueRefs}
                id="Comentario"
                label="Comentario:"
                type="text"
                placeholder=""
                Avalue={data.Comentario}
                reset={reset}
              />

              
          </div>

          <div className='row'>


              <div className="col-md-4 col-sm-4">
                <label className="form-label">Estado</label>
                  <select
                    className="form-control"
                    id="Estado"
                    value={data.Estado ? "true" : "false"}
                    onChange={(e) => setData({ ...data, Estado: e.target.value === "true" })}
                    ref={EstadoRef} // Asignando la referencia
                  >
                    <option value="true">Activo</option>
                    <option value="false">Inactivo</option>
                  </select>
              </div>
              <div className="col-md-4 col-sm-4">
              <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Ingreso"
                    value={inTime}
                    onChange={(newValue) => setInTime(newValue)}
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>

            <div className="col-md-4 col-sm-4">
              <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Retiro"
                    value={outTime}
                    onChange={(newValue) => setOutTime(newValue)}
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>


          </div>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleEdit}>Editar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
