import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import env from '../../../env-config';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { esES } from '@mui/x-date-pickers/locales';
import 'bootstrap/dist/css/bootstrap.min.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditCCTModal({ open, handleClose }) {
  const [ID, setID] = useState('');
  const [data, setData] = useState({});
  // eslint-disable-next-line 
  const [reset, setReset] = useState(false);
  const [CCFTime, setCCFTime] = useState(null); 
  const [D195Time, setD195Time] = useState(null); 
  const [D140Time, setD140Time] = useState(null); 

  useEffect(() => {
    if (open) {
      const ID = sessionStorage.getItem('SocietyID');
      setID(ID);
  
      const fetchData = async () => {
        const data2 = { ID };
        try {
          const response = await axios.post(env.API_URL + 'GetCCT', data2);
          const formattedData = response.data[0];
  
          const adjustDate = (dateStr) => {
            if (!dateStr) return null;
            const date = dayjs(dateStr);
            const originalDay = parseInt(dateStr.split('T')[0].split('-')[2], 10);
            const adjustedDay = date.date();
            const dayDifference = adjustedDay - originalDay;
  
            if (dayDifference !== 0) {
              return date.add(-dayDifference, 'day');
            }
            return date;
          };
  
          // Ajustar y establecer las fechas correspondientes
          setCCFTime(adjustDate(formattedData.UltimaFecha));
          setD140Time(adjustDate(formattedData.UltimaFechaD140));
          setD195Time(adjustDate(formattedData.UltimaFechaD195));
  
          setData(formattedData);
        } catch (error) {
          console.error("Error en traer la data de la sociedad", error.message);
        }
      };
      fetchData();
    } else {
      setReset(true);
      setID('');
      setData({});
      setCCFTime(null);
      setD140Time(null);
      setD195Time(null);
      setTimeout(() => setReset(false), 0);
    }
  }, [open]);


  const detectChanges = () => {
    const changes = [];
    const actualUser = sessionStorage.getItem('loginNick');
    const SocietyName = sessionStorage.getItem('SocietyName');  
  
    // Comparar valores de select

    if (document.getElementById('RTBF').value !== (data.RTBF ? "true" : "false")) {
      const oldValue = data.RTBF ? "Sí" : "No";
      const newValue = document.getElementById('RTBF').value === "true" ? "Sí" : "No";
      changes.push(`Se ha cambiado RTBF de "${oldValue}" por "${newValue}"`);
    }
    if (document.getElementById('D140').value !== (data.D140 ? "true" : "false")) {
      const oldValue = data.D140 ? "Sí" : "No";
      const newValue = document.getElementById('D140').value === "true" ? "Sí" : "No";
      changes.push(`Se ha cambiado D140 de "${oldValue}" por "${newValue}"`);
    }
    if (document.getElementById('D195').value !== (data.D195 ? "true" : "false")) {
      const oldValue = data.D195 ? "Sí" : "No";
      const newValue = document.getElementById('D195').value === "true" ? "Sí" : "No";
      changes.push(`Se ha cambiado D195 de "${oldValue}" por "${newValue}"`);
    }
  
    // Comparar valores de fechas
    const adjustDate = (dateStr) => {
      if (!dateStr) return null;
      const date = dayjs(dateStr);
      const originalDay = parseInt(dateStr.split('T')[0].split('-')[2], 10);
      const adjustedDay = date.date();
      const dayDifference = adjustedDay - originalDay;

      if (dayDifference !== 0) {
        return date.add(-dayDifference, 'day');
      }
      return date;
    };




    if (CCFTime && !dayjs(CCFTime).isSame(adjustDate(data.UltimaFecha, 'day'))) {
      const oldDate = adjustDate(data.UltimaFecha) ? dayjs(adjustDate(data.UltimaFecha)).format('DD-MM-YYYY') : "N/A";
      const newDate = dayjs(CCFTime).format('DD-MM-YYYY');
      changes.push(`Se ha cambiado la fecha RTBF de "${oldDate}" por "${newDate}"`);
    }
    if (D140Time && !dayjs(D140Time).isSame(adjustDate(data.UltimaFechaD140, 'day'))) {
      const oldDate = adjustDate(data.UltimaFechaD140) ? dayjs(adjustDate(data.UltimaFechaD140)).format('DD-MM-YYYY') : "N/A";
      const newDate = dayjs(D140Time).format('DD-MM-YYYY');
      changes.push(`Se ha cambiado la fecha D140 de "${oldDate}" por "${newDate}"`);
    }
    if (D195Time && !dayjs(D195Time).isSame(adjustDate(data.UltimaFechaD195, 'day'))) {
      const oldDate = adjustDate(data.UltimaFechaD195) ? dayjs(adjustDate(data.UltimaFechaD195)).format('DD-MM-YYYY') : "N/A";
      const newDate = dayjs(D195Time).format('DD-MM-YYYY');
      changes.push(`Se ha cambiado la fecha D195 de "${oldDate}" por "${newDate}"`);
    }
  
    return `El usuario ${actualUser} realizó los siguientes cambios en CCT de la sociedad ${SocietyName}: ${changes.join(' * ')}`;
  };

  const handleSelectChange = (e) => {
    const { id, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleEdit = async () => {
    const idLogin = sessionStorage.getItem('loginUser');

    const description = detectChanges();
    console.log('description', description);

    const rawData = {
      ID,
      RTBF: document.getElementById("RTBF").value === "true",
      D140: document.getElementById("D140").value === "true",
      D195: document.getElementById("D195").value === "true",
      LastRTBF: CCFTime ? dayjs(CCFTime).format('YYYY-MM-DD') : null,
      LastD140: D140Time ? dayjs(D140Time).format('YYYY-MM-DD') : null,
      LastD195: D195Time ? dayjs(D195Time).format('YYYY-MM-DD') : null,
      IDUSUARIO: idLogin
    };
    

    try {
      const response = await axios.put(env.API_URL + 'UpdateCCT', rawData);

      if (response.status === 200) {
        const dataBit = {
          ID: idLogin,
          Descripcion: description
        }

        await axios.post(env.API_URL+'AddActionRecord', dataBit);
        Swal.fire('Success', 'Cumplimiento Corporativo Tributario se ha editado exitosamente', 'success').then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire('Error', 'Hubo un problema al editar el Cumplimiento Corporativo Tributario', 'error');
      }
      handleClose();
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al establecer la conexión', 'error');
      handleClose();
    }
      
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{`Editar CCT`}</DialogTitle>
        <DialogContent>
          <div className='Row'>
            <div className="col-md-4 col-sm-4">
              <label className="form-label">RTBF:</label>
              <select className="form-control" id="RTBF"
              value={data.RTBF || ''}
              onChange={handleSelectChange}>
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>
            <div className="col-md-4 col-sm-4">
              <label className="form-label">D140:</label>
              <select className="form-control" id="D140"
              value={data.D140 || ''}
              onChange={handleSelectChange}>
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>
            <div className="col-md-4 col-sm-4">
              <label className="form-label">D195:</label>
              <select className="form-control" id="D195"
              value={data.D195 || ''}
              onChange={handleSelectChange}>
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>

          <div className='Row'>
            <div className="col-md-4 col-sm-4">
              <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Fecha RTBF"
                    value={CCFTime}
                    onChange={(newValue) => setCCFTime(newValue)}
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>

            <div className="col-md-4 col-sm-4">
              <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Fecha CCF"
                    value={D140Time}
                    onChange={(newValue) => setD140Time(newValue)}
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>

            <div className="col-md-4 col-sm-4">
              <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Fecha D195"
                    value={D195Time}
                    onChange={(newValue) => setD195Time(newValue)}
                    format="DD-MM-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleEdit}>Editar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
