import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import { DeleteFilled, EditFilled, ReadOutlined, TeamOutlined, FilePdfOutlined, CarOutlined, HomeOutlined } from '@ant-design/icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import env from '../../env-config';
import EditUser from '../../subcomponents/modals/Edit/EditCustomer';
import { Button, Spin } from 'antd';

const Title = "Clientes";
const columns = ["ID", "Nombre", "Identificacion", "Tipo Identificador", "Correo", "Direccion", "Abogado", "PM", "FB"];

const Home = () => {
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Estado de carga

  // Lógica para abrir el modal de edición
  const handleEditModalOpen = () => {
    if (selectedRows.length === 0) return;

    const selectedUser = data[selectedRows[0].dataIndex];
    const ID = selectedUser[0];
    sessionStorage.setItem('CustomerID', ID);
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => setEditModalOpen(false);

  // Lógica para eliminar
  const handleDelete = async () => {
    if (selectedRows.length === 0) return;

    const selectedUser = data[selectedRows[0].dataIndex];
    const id = selectedUser[0];
    const nombre = selectedUser[1]; 
    const cedula = selectedUser[2];
    const idLogin = sessionStorage.getItem('loginUser');
    const actualUser = sessionStorage.getItem('loginNick');

    try {
      const response = await axios.post(env.API_URL + 'DeleteCustomer', {
        ID: id,
        IDUSUARIO: idLogin,
        IDDelete: cedula,
      });
      if (response.status === 200) {
        const dataBit = {
          ID: idLogin,
          Descripcion: "El usuario " + actualUser + " eliminó el cliente con cédula " + cedula + " y nombre " + nombre
        }

        await axios.post(env.API_URL+'AddActionRecord', dataBit);
        Swal.fire('Success', 'Cliente se ha eliminado exitosamente', 'success').then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire('Error', 'Hubo un problema al eliminar el cliente', 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al eliminar el cliente', 'error');
    }
  };

  // Cargar datos
  useEffect(() => {
    sessionStorage.setItem('currentPage', 'Clientes');
    const user = sessionStorage.getItem('loginUser');
    if (!user) {
      window.location.href = '/login';
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(env.API_URL + 'GetCustomers');
        const formattedData = response.data.map(user => [
          user.ID,
          user.Nombre,
          user.Cedula,
          user.Tipo_Cedula,
          user.Correo,
          user.Direccion,
          user.Abogado,
          user.PM,
          user.FB,
        ]);
        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data", error.message);
      } finally {
        setIsLoading(false); // Cambia el estado de carga
      }
    };

    fetchData();
  }, []);

  const customToolbarSelect = () => {
    const handleBienes = () => {
      const selectedUser = data[selectedRows[0].dataIndex];
      const id = selectedUser[0];
      const nombre = selectedUser[1];
      sessionStorage.setItem('CustomerName',nombre);
      sessionStorage.setItem('CustomerID', id);
      sessionStorage.setItem('currentPage', 'Bienes');
      sessionStorage.setItem('TipoBien', 'Cliente');
      sessionStorage.setItem('lastPage', 'Clientes');
      window.location.href = '/bienes';
    };

    const handleSociedades = () => {
      if (selectedRows.length === 0) return;

      const selectedUser = data[selectedRows[0].dataIndex];
      const id = selectedUser[0];
      const nombre = selectedUser[1];
      sessionStorage.setItem('CustomerName',nombre);
      sessionStorage.setItem('CustomerID', id);
      sessionStorage.setItem('lastPage', 'Clientes');
      window.location.href = '/sociedades';
    };

    const handleDocumentos = () => {
      const selectedUser = data[selectedRows[0].dataIndex];
      const id = selectedUser[0];
      const nombre = selectedUser[1];
      sessionStorage.setItem('CustomerName',nombre);
      sessionStorage.setItem('CustomerID', id);
      window.location.href = '/documentos';
    };

    const handleMuebles = () => {
      const selectedUser = data[selectedRows[0].dataIndex];
      const id = selectedUser[0];
      const nombre = selectedUser[1];
      sessionStorage.setItem('CustomerName',nombre);
      sessionStorage.setItem('CustomerID', id);
      sessionStorage.setItem('TipoBien', 'Cliente');
      sessionStorage.setItem('lastPage', 'Clientes');
      window.location.href = '/muebles';
    };

    const handleInmuebles = () => {
      const selectedUser = data[selectedRows[0].dataIndex];
      const id = selectedUser[0];
      const nombre = selectedUser[1];
      sessionStorage.setItem('CustomerName',nombre);
      sessionStorage.setItem('CustomerID', id);
      sessionStorage.setItem('TipoBien', 'Cliente');
      sessionStorage.setItem('lastPage', 'Clientes');
      window.location.href = '/inmuebles';
    };

    return (
      <div>
        <Button type='text' className='toggle' icon={<FilePdfOutlined />} onClick={handleDocumentos} title="Ver documentos" />
        <Button type='text' className='toggle' icon={<CarOutlined />} onClick={handleMuebles} title="Ver Muebles" />
        <Button type='text' className='toggle' icon={<HomeOutlined />} onClick={handleInmuebles} title="Ver Inmuebles" />
        <Button type='text' className='toggle' icon={<ReadOutlined />} onClick={handleBienes} title="Ver bienes" />
        <Button type='text' className='toggle' icon={<TeamOutlined />} onClick={handleSociedades} title="Ver Sociedades" />
        <Button type='text' className='toggle' icon={<EditFilled />} title="Editar" onClick={handleEditModalOpen} />
        <Button type='text' className='toggle' icon={<DeleteFilled />} onClick={handleDelete} title="Borrar" />
      </div>
    );
  };

  return (
    <>
      <EditUser open={editModalOpen} handleClose={handleEditModalClose} />
      {isLoading ? (
        <Spin tip="Cargando datos..." style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }} />
      ) : (
        <Table 
          title={Title} 
          data={data} 
          columns={columns} 
          customToolbarSelectFunction={customToolbarSelect} 
          setSelectedRows={setSelectedRows}
        />
      )}
    </>
  );
};

export default Home;
