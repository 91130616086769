import React, {  useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import env from '../../../env-config';



import 'bootstrap/dist/css/bootstrap.min.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line 
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditDocumentsModal({ open, handleClose }) {
  const [ID, setID] = useState('');
  const [data, setData] = useState({});
  // eslint-disable-next-line 
  const [reset, setReset] = useState(false);




  useEffect(() => {
    if (open) {
      const ID = sessionStorage.getItem('CustomerID');
      setID(ID);

      const fetchData = async () =>{
        const data2 = {
          ID: ID
        }
        try {
          const response = await axios.post(env.API_URL + 'GetDocuments', data2);
          const formattedData = response.data[0];

          setData(formattedData);
        } catch (error) {
          console.error("Error en traer la data de la sociedad", error.message);
        }
      }
      fetchData();
    } else {
      // Reset the values when the modal is closed
      setReset(true);
      setTimeout(() => setReset(false), 0);
    }
  }, [open]);


  const handleSelectChange = (e) => {
    const { id, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };


  const detectChanges = () => {
    const changes = [];
    const actualUser = sessionStorage.getItem('loginUser');
    const customerName = sessionStorage.getItem('CustomerName');
  
    // Comparar valores de selects
    if (document.getElementById('Poderes').value !== (data.Poderes ? "true" : "false")) {
      const oldValue = data.Poderes ? "Presenta" : "No Presenta";
      const newValue = document.getElementById('Poderes').value === "true" ? "Presenta" : "No Presenta";
      changes.push(`Se ha cambiado Poderes de "${oldValue}" a "${newValue}"`);
    }
    if (document.getElementById('Testamentos').value !== (data.Testamentos ? "true" : "false")) {
      const oldValue = data.Testamentos ? "Presenta" : "No Presenta";
      const newValue = document.getElementById('Testamentos').value === "true" ? "Presenta" : "No Presenta";
      changes.push(`Se ha cambiado Testamentos de "${oldValue}" a "${newValue}"`);
    }
    if (document.getElementById('Contratos').value !== (data.Contratos ? "true" : "false")) {
      const oldValue = data.Contratos ? "Presenta" : "No Presenta";
      const newValue = document.getElementById('Contratos').value === "true" ? "Presenta" : "No Presenta";
      changes.push(`Se ha cambiado Contratos de "${oldValue}" a "${newValue}"`);
    }
  
    // Devolver mensaje de cambios
    if (changes.length > 0) {
      return `El usuario ${actualUser} realizó los siguientes cambios de los documentos asociado al cliente ${customerName} : ${changes.join(' * ')}`;
    } else {
      return `No se realizaron cambios.`;
    }
  };
  
  

  const handleEdit = async () => {

    const description = detectChanges(); 


    const idLogin = sessionStorage.getItem('loginUser');
    const rawData = {
      ID: ID,
      Poderes: document.getElementById("Poderes").value === "true",
      Testamentos: document.getElementById("Testamentos").value === "true",
      Contratos: document.getElementById("Contratos").value === "true",
      IDUSUARIO: idLogin

    };


  
    try {
      const response = await axios.put(env.API_URL + 'UpdateDocuments', rawData);

      if (response.status === 200) {
        const dataBit = {
          ID: idLogin,
          Descripcion: description
        }

        await axios.post(env.API_URL+'AddActionRecord', dataBit);
        Swal.fire('Success', 'Documentos se ha editado exitosamente', 'success').then(() => {
          window.location.reload();
        });
      } else {

        Swal.fire('Error', 'Hubo un problema al editar Documentos', 'error');
      }
      handleClose();
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al establecer la conexión', 'error');
      handleClose();
    }
      
      
  };


  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{`Editar Documentos`}</DialogTitle>
        <DialogContent>
        <div className='Row'>
            <div className="col-md-4 col-sm-4">
                  <label className="form-label">Poderes:</label>
                  <select className="form-control" id="Poderes"
                  value={data.Poderes }
                  onChange={handleSelectChange}>
                    <option value="true">Presenta</option>
                    <option value="false">No Presenta</option>
                  </select>
              </div>
              <div className="col-md-4 col-sm-4">
                  <label className="form-label">Testamentos:</label>
                  <select className="form-control" id="Testamentos"
                  value={data.Testamentos }
                  onChange={handleSelectChange}>
                    <option value="true">Presenta</option>
                    <option value="false">No Presenta</option>
                  </select>
              </div>
              <div className="col-md-4 col-sm-4">
                  <label className="form-label">Contratos:</label>
                  <select className="form-control" id="Contratos"
                  value={data.Contratos}
                  onChange={handleSelectChange}>
                    <option value="true">Presenta</option>
                    <option value="false">No Presenta</option>
                  </select>
              </div>
          </div>


          


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleEdit}>Editar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
